import { motion } from 'framer-motion';
import styled from 'styled-components';

const Container = styled.div`
  padding: 64px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function App() {
  return (
    <Container>
      <motion.img
        srcSet="mr-a.png 3x"
        alt="Mr.A"
        whileTap={{ scale: 0.9 }}
        transition={{
          type: 'spring',
          mass: 2,
          damping: 20,
          stiffness: 300,
          restDelta: 0.0001,
        }}
        draggable="false"
      />
    </Container>
  );
}

export default App;
